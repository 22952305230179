import {type Config} from '../../config';

/**
 * Tests if the current domain is a preview domain. A preivew domain indicates
 * that the "domain" of the site is part of the pathname.
 *
 * **WARNING** This makes an assumption about how the `@backstage/ui` package
 * connects to the API and how that is related to the preview / published
 * relationship. For now this assumption is valid, but it may not be in the
 * future.
 *
 * **Example Preview Domains** (assuming an API endpoint of
 * https://api.lcdbackstage.com/)
 *
 * - https://localhost:3000/
 * - https://lcdbackstage.com/
 * - https://preview.lcdbackstage.com/
 *
 * @param config object with endpoint information.
 * @param location object with hostname information to check.
 * @returns `true` if the hostname of `location` should be treated as a preview
 * domain, indicating that the "domain" of the site is a part of the pathname.
 * Always `true` on "localhost".
 */
export function isPreviewDomain(config: Config, location?: Loc): boolean {
  if (typeof location === 'undefined' || config.endpointBase.startsWith('/')) {
    return false;
  }
  const hostname = location.hostname || location.host;
  // if the endpoint looks like a path, pass the current domain as the base of
  // the endpoint
  const urlBase = config.endpointBase.startsWith('/')
    ? `${location.protocol}//${location.hostname}`
    : undefined;
  const endpoint = new URL(config.endpointBase, urlBase);
  const endpointHostnameParts = endpoint.hostname.split('.');
  const endpointBase =
    endpointHostnameParts.length >= 3
      ? endpoint.hostname.split('.').slice(1).join('.')
      : endpoint.hostname;
  return (
    hostname === 'localhost' ||
    hostname === endpointBase ||
    hostname.startsWith('preview.')
  );
}

/**
 * Tests if the current domain is a "live preview" domain. A "live preview"
 * domain indicates that the "domain" of the site is either the location
 * hostname or passed as a query parameter.
 *
 * **WARNING** This makes an assumption about how the `@backstage/ui` package
 * connects to the API and how that is related to the live preview / published
 * relationship. For now this assumption is valid, but it may not be in the
 * future.
 *
 * **Example Live Preview Domains** (assuming an API endpoint of
 * https://api.lcdbackstage.com/)
 *
 * - https://live.lcdbackstage.com/
 *
 * @param config object with endpoint information.
 * @param location object with hostname information to check.
 * @returns `true` if the hostname of the `location` should be treated as a
 * "live preview" domain.
 */
export function isLivePreviewHost(config: Config, location?: Loc): boolean {
  if (typeof location === 'undefined') {
    return false;
  }
  const hostname = location.hostname || location.host;
  // if the endpoint looks like a path, pass the current domain as the base of
  // the endpoint
  const urlBase = config.endpointBase.startsWith('/')
    ? `${location.protocol}//${location.hostname}`
    : undefined;
  const endpoint = new URL(config.endpointBase, urlBase);
  const endpointHostnameParts = endpoint.hostname.split('.');
  const endpointBase =
    endpointHostnameParts.length >= 3
      ? endpoint.hostname.split('.').slice(-2).join('.')
      : endpoint.hostname;
  return hostname.endsWith(endpointBase) && hostname.startsWith('live.');
}

/**
 * Subset of `Location` properties used for determining type of host.
 */
type Loc = Pick<Location, 'host' | 'hostname' | 'protocol'>;
