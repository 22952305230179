import {
  orientationAttr,
  OptionalString,
  StringEnum,
  styleAttr,
  styleAttrUi,
  animationStates,
  animationUi,
  ComponentAdmin,
  SubscribesTo,
  animationDescription,
  scrollToDescription,
} from '@backstage-components/base';
import {Static, Type} from '@sinclair/typebox';

export const reactName = 'Stacked';
export const name = reactName;
export const description = reactName;

export const defaultFieldData = {
  layout: {
    orientation: 'vertical',
  },
  backgroundSize: 'cover',
  backgroundPosition: 'center',
  backgroundRepeat: 'no-repeat',
};

const presets = [
  'start',
  'center',
  'end',
  'space-between',
  'space-around',
  'space-evenly',
] as const;
export type Preset = typeof presets[number];

const align = ['start', 'center', 'end'] as const;
export type Align = typeof align[number];

export const schema = Type.Object(
  {
    displayName: OptionalString({title: 'Display Name'}),
    layout: Type.Optional(
      Type.Object(
        {
          orientation: orientationAttr,
          preset: Type.Optional(
            StringEnum(presets, {
              title: 'Preset',
            })
          ),
          align: Type.Optional(StringEnum(align, {title: 'Align'})),
        },
        {
          title: 'Layout Settings',
          description:
            'If a "preset" is selected, will return a flex-layout based on selection. Falls back on orientation',
        }
      )
    ),
    autoLayout: Type.Optional(
      Type.Boolean({
        title: 'Enable Auto Layout',
        default: false,
      })
    ),
    gap: OptionalString({title: 'Gap Sizing'}),
    margin: OptionalString({title: 'Margin'}),
    padding: OptionalString({title: 'Padding'}),
    backgroundAttachment: OptionalString('Background Attachment'),
    backgroundColor: OptionalString({title: 'Background Color'}),
    backgroundImage: OptionalString({title: 'Background Image'}),
    backgroundRepeat: OptionalString({title: 'Background Repeat'}),
    backgroundPosition: OptionalString({title: 'Background Position'}),
    backgroundSize: OptionalString({title: 'Background Size'}),
    maxHeight: OptionalString({title: 'Max Height'}),
    minHeight: OptionalString({title: 'Min Height'}),
    height: OptionalString({title: 'Height'}),
    animationStates,
    styleAttr,
  },
  {
    description:
      'If autoLayout is checked, a flex property shorthand will be passed to children. False by default',
  }
);

export type SchemaType = Static<typeof schema>;

export const uiSchema = {
  ...styleAttrUi,
  ...animationUi,
};

export const instructions = Type.Union([
  SubscribesTo({
    topic: `${reactName}:animationState`,
    description: animationDescription,
    meta: {
      stateName: Type.String(),
    },
  }),
  SubscribesTo({
    topic: `${reactName}:scrollTo`,
    description: scrollToDescription,
    meta: {
      scrollX: Type.Optional(Type.String()),
      scrollY: Type.Optional(Type.String()),
      anchorElId: Type.Optional(Type.String()),
      elementId: Type.Optional(Type.String()),
    },
  }),
]);

export const ComponentDefinition: ComponentAdmin = {
  id: '57accedd-28cd-4c27-9d27-2b185af3a88c',
  reactName,
  name: 'Stacked Layout',
  slug: reactName,
  description: 'Stacked layout',
  version: 1,
  defaultFieldData,
  slotConfiguration: {
    items: {maxModules: 100, displayWidth: 12, title: 'Modules'},
  },
  schema,
  uiSchema,
  instructions,
};
