import {useShowInstructions, LayoutModule} from '@backstage-components/base';
import {useSubscription} from 'observable-hooks';
import {useEffect, FC} from 'react';
import {
  useNavigate as useRouterNavigate,
  useLocation,
  Routes,
} from 'react-router';
import {reactName, RouterInstructionSchema} from './RouterDefinition';

export type RouterComponentDefinition = LayoutModule<
  typeof reactName,
  RouterContainerProps
>;

/**
 * Creates a `react-router` `Routes` node which also subscribes to `Router`
 * instructions in order to manage page navigation with the site `Instruction`
 * flows.
 */
export const RouterContainer: FC<RouterContainerProps> = (props) => {
  const location = useLocation();
  const navigate = useRouterNavigate();
  const {observable, broadcast} = useShowInstructions(RouterInstructionSchema);
  useSubscription(observable, {
    next: (instruction) => {
      if (instruction.type === 'Router:goto') {
        navigate(`${props.prefix ?? ''}${instruction.meta.path}`);
      }
    },
  });
  // broadcast a route change instruction when location.pathname changes
  useEffect(() => {
    const pathname = props.prefix
      ? location.pathname.replace(props.prefix, '')
      : location.pathname;
    const currentPath = pathname === '' ? '/' : pathname;
    broadcast({
      type: 'Router:on-navigate',
      meta: {currentPath},
    });
    setTimeout(() =>
      broadcast({
        type: 'Router:on-navigate-done',
        meta: {currentPath},
      })
    );
  }, [broadcast, location.pathname, props.prefix]);
  return <Routes>{props.children}</Routes>;
};

export interface RouterContainerProps {
  /**
   * If provided, `prefix` is prepended to every path before navigation is
   * triggered.
   */
  prefix?: string;
}
