import type {BroadcastFn} from '@backstage-components/base';
import {useEffect, useState} from 'react';
import {instructions, type Speaker} from './RtcRoomContainerDefinition';
import {
  RoomEvent,
  Kit,
  ConnectionQuality,
  Participant,
} from '@backstage-components/livekit-client';

export function useRoomEvents(
  maybeKit: Kit | undefined,
  broadcast: BroadcastFn<typeof instructions>
): void {
  useKitEffect(
    (kit) => {
      const broadcastFunc = (participants: Participant[]): void =>
        broadcast({
          type: 'RtcRoomContainer:on-active-speaker-changed',
          meta: {
            participants: participants.map((p) => ({
              sid: p.sid,
              identity: p.identity,
              name: p.name,
              metadata: p.metadata,
              isSpeaking: p.isSpeaking,
              audioLevel: p.audioLevel,
              isLocal: kit?.room
                ? kit?.room?.localParticipant.isSpeaking
                : false,
            })),
          },
        });
      kit?.room?.on(RoomEvent.ActiveSpeakersChanged, broadcastFunc);
      return () => {
        kit?.room?.off(RoomEvent.ActiveSpeakersChanged, broadcastFunc);
      };
    },
    {kit: maybeKit, deps: [broadcast]}
  );
  useKitEffect(
    (kit) => {
      const broadcastFunc = (): void =>
        broadcast({
          type: 'RtcRoomContainer:on-audio-playback-change',
          meta: {},
        });
      kit?.room?.on(RoomEvent.AudioPlaybackStatusChanged, broadcastFunc);
      return () => {
        kit?.room?.off(RoomEvent.AudioPlaybackStatusChanged, broadcastFunc);
      };
    },
    {kit: maybeKit, deps: [broadcast]}
  );
  useKitEffect(
    (kit) => {
      const broadcastFunc = (
        quality: ConnectionQuality,
        participant: Participant
      ): void =>
        broadcast({
          type: 'RtcRoomContainer:on-connection-quality-changed',
          meta: {
            quality,
            participantSid: participant.sid,
          },
        });
      kit?.room?.on(RoomEvent.ConnectionQualityChanged, broadcastFunc);
      return () => {
        kit?.room?.off(RoomEvent.ConnectionQualityChanged, broadcastFunc);
      };
    },
    {kit: maybeKit, deps: [broadcast]}
  );
  useKitEffect(
    (kit) => {
      const broadcastFunc = (): void =>
        broadcast({
          type: 'RtcRoomContainer:on-local-track-published',
          meta: {},
        });
      kit?.room?.on(RoomEvent.LocalTrackPublished, broadcastFunc);
      return () => {
        kit?.room?.off(RoomEvent.LocalTrackPublished, broadcastFunc);
      };
    },
    {kit: maybeKit, deps: [broadcast]}
  );
  useKitEffect(
    (kit) => {
      const broadcastFunc = (): void =>
        broadcast({
          type: 'RtcRoomContainer:on-local-track-unpublished',
          meta: {},
        });
      kit?.room?.on(RoomEvent.LocalTrackUnpublished, broadcastFunc);
      return () => {
        kit?.room?.off(RoomEvent.LocalTrackUnpublished, broadcastFunc);
      };
    },
    {kit: maybeKit, deps: [broadcast]}
  );
  useKitEffect(
    (kit) => {
      const broadcastFunc = (): void =>
        broadcast({
          type: 'RtcRoomContainer:on-media-devices-error',
          meta: {},
        });
      kit?.room?.on(RoomEvent.MediaDevicesError, broadcastFunc);
      return () => {
        kit?.room?.off(RoomEvent.MediaDevicesError, broadcastFunc);
      };
    },
    {kit: maybeKit, deps: [broadcast]}
  );
  useKitEffect(
    (kit) => {
      const broadcastFunc = (): void =>
        broadcast({
          type: 'RtcRoomContainer:on-media-devices-changed',
          meta: {},
        });
      kit?.room?.on(RoomEvent.MediaDevicesChanged, broadcastFunc);
      return () => {
        kit?.room?.off(RoomEvent.MediaDevicesChanged, broadcastFunc);
      };
    },
    {kit: maybeKit, deps: [broadcast]}
  );
  useKitEffect(
    (kit) => {
      const broadcastFunc = (): void => {
        broadcast({
          type: 'RtcRoomContainer:on-track-muted',
          meta: {},
        });
      };
      kit?.room?.on(RoomEvent.TrackMuted, broadcastFunc);
      return () => {
        kit?.room?.off(RoomEvent.TrackMuted, broadcastFunc);
      };
    },
    {kit: maybeKit, deps: [broadcast]}
  );
  useKitEffect(
    (kit) => {
      const broadcastFunc = (): void =>
        broadcast({
          type: 'RtcRoomContainer:on-track-unmuted',
          meta: {},
        });

      kit?.room?.on(RoomEvent.TrackUnmuted, broadcastFunc);
      return () => {
        kit?.room?.off(RoomEvent.TrackUnmuted, broadcastFunc);
      };
    },
    {kit: maybeKit, deps: [broadcast]}
  );
  useKitEffect(
    (kit) => {
      const broadcastFunc = (): void =>
        broadcast({
          type: 'RtcRoomContainer:on-state-changed',
          meta: {},
        });

      kit?.room?.on(RoomEvent.StateChanged, broadcastFunc);
      return () => {
        kit?.room?.off(RoomEvent.StateChanged, broadcastFunc);
      };
    },
    {kit: maybeKit, deps: [broadcast]}
  );
}

type Destructor = () => void;
type KitEffectCallback = (kit: Kit) => void | Destructor;

function useKitEffect(
  effect: KitEffectCallback,
  options: {kit: Kit | undefined; deps: ReadonlyArray<unknown>}
): void {
  const {kit, deps} = options;
  useEffect(() => {
    if (!kit) {
      return;
    }
    return effect(kit);
  }, [deps, effect, kit]);
}

export function useSpeakerChangeBroadcasts(
  broadcast: BroadcastFn<typeof instructions>,
  kit?: Kit
): void {
  const [activeSpeakers, setActiveSpeakers] = useState<Speaker[]>([]);
  useEffect(() => {
    if (!kit) {
      return;
    }
    const onSpeakersChange = (participants: Participant[]): void => {
      const currentSpeakers = participants.map<Speaker>((p) => ({
        sid: p.sid,
        identity: p.identity,
        name: p.name,
        metadata: p.metadata,
        isSpeaking: p.isSpeaking,
        audioLevel: p.audioLevel,
        isLocal: p.sid === kit?.room?.localParticipant.sid,
      }));
      const currentSpeakerIds = currentSpeakers.map((s) => s.sid);
      const previousSpeakerIds = activeSpeakers.map((s) => s.sid);
      const addedSpeakers = currentSpeakers.filter(
        (s) => !previousSpeakerIds.includes(s.sid)
      );
      const droppedSpeakers = activeSpeakers.filter(
        (s) => !currentSpeakerIds.includes(s.sid)
      );
      for (const droppedSpeaker of droppedSpeakers) {
        broadcast({
          type: 'RtcRoomContainer:on-speaker-stop',
          meta: {
            ...droppedSpeaker,
          },
        });
      }
      for (const addedSpeaker of addedSpeakers) {
        broadcast({
          type: 'RtcRoomContainer:on-speaker-start',
          meta: {
            ...addedSpeaker,
          },
        });
      }
      setActiveSpeakers(currentSpeakers);
    };
    kit?.room?.on(RoomEvent.ActiveSpeakersChanged, onSpeakersChange);
    return () => {
      kit?.room?.off(RoomEvent.ActiveSpeakersChanged, onSpeakersChange);
    };
  }, [activeSpeakers, broadcast, kit]);
}
