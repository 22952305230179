import type {BroadcastFn} from '@backstage-components/base';
import {assign, createMachine} from 'xstate';
import {
  AccessCodeInstruction,
  AccessCodeInstructionSchema,
} from './AccessCodeDefinition';

export const AccessCodeMachine = createMachine<
  Context,
  AccessCodeInstruction,
  Typestate
>(
  {
    id: 'AccessCode',
    initial: 'idle',
    states: {
      idle: {
        on: {
          'AccessCode:verify': {
            target: 'pending',
            actions: ['performVerify'],
          },
        },
      },
      pending: {
        on: {
          'AccessCode:success': {
            target: 'success',
            actions: ['updateAttendee'],
          },
          'AccessCode:failure': {
            target: 'failure',
          },
        },
      },
      success: {
        entry: ['broadcastSuccess'],
        on: {
          'AccessCode:verify': {
            target: 'pending',
            actions: ['performVerify'],
          },
        },
      },
      failure: {
        entry: ['broadcastFailure', 'clearAttendeeId'],
        on: {
          'AccessCode:reset': 'idle',
          'AccessCode:verify': {
            target: 'pending',
            actions: ['performVerify'],
          },
        },
      },
    },
  },
  {
    actions: {
      broadcastFailure: (context, event) => {
        if (event.type === 'AccessCode:failure') {
          context.broadcast({
            type: 'AccessCode:on-failure',
            meta: {
              showId: context.showId,
              reason: event.meta.reason,
            },
          });
        }
      },
      broadcastSuccess: (context, event) => {
        if (event.type === 'AccessCode:success') {
          if (typeof window === 'object') {
            const e: AccessCodeSuccessEvent = new CustomEvent(
              AccessCodeSuccessEventName,
              {detail: {attendee: event.meta.attendee, showId: context.showId}}
            );
            document.body.dispatchEvent(e);
          }
        }
      },
      clearAttendeeId: assign((context, event) => {
        if (event.type === 'AccessCode:failure') {
          // Return `BaseContext`
          return {
            attendee: undefined,
            broadcast: context.broadcast,
            showId: context.showId,
          };
        } else {
          return context;
        }
      }),
      performVerify: (context, event) => {
        if (event.type === 'AccessCode:verify') {
          context.broadcast({type: event.type, meta: event.meta});
        }
      },
      updateAttendee: assign((context, event) => {
        if (event.type === 'AccessCode:success') {
          const attendee = event.meta.attendee;
          const token = attendee.chatTokens.find(
            (token) => token.token.length > 0
          )?.token;
          return {
            broadcast: context.broadcast,
            showId: context.showId,
            attendee,
            token,
          };
        } else {
          return context;
        }
      }),
    },
  }
);

interface BaseContext {
  broadcast: BroadcastFn<typeof AccessCodeInstructionSchema>;
  showId: string;
  attendee?: Attendee;
}

interface SuccessContext extends BaseContext {
  attendee: Attendee;
}

type Context = SuccessContext | BaseContext;

type Typestate =
  | {value: 'idle'; context: BaseContext}
  | {value: 'pending'; context: BaseContext}
  | {value: 'success'; context: SuccessContext}
  | {value: 'failure'; context: BaseContext};

interface Attendee {
  id: string;
  email: string | null;
  name: string;
  chatTokens: ChatToken[];
}

interface ChatToken {
  token: string;
}

export const AccessCodeSuccessEventName = 'AccessCode:success';

export type AccessCodeSuccessEvent = CustomEvent<
  Pick<SuccessContext, 'attendee' | 'showId'>
>;
