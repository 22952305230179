import {VFC, useEffect, useCallback, useRef} from 'react';
import {css} from '@emotion/react';
import {
  ContentModule,
  extractModuleIdentifiers,
  ModuleProperties,
  useParsedText,
  useShowId,
  useShowInstructions,
} from '@backstage-components/base';
import {ButtonComponent, ButtonProps} from '@backstage-components/button';
import {
  TextInputProps,
  TextInputComponent,
} from '@backstage-components/text-input';
import {useMachine} from '@xstate/react';
import {useSubscription} from 'observable-hooks';
import {useForm, SubmitHandler, FieldValues} from 'react-hook-form';
import {SchemaType, AccessCodeInstructionSchema} from './AccessCodeDefinition';
import * as Styled from './AccessCode.styled';
import {
  AccessCodeMachine,
  type AccessCodeSuccessEvent,
} from './AccessCodeMachine';

export const defaultAccessCodeProps: Required<
  Pick<
    IAccessCodeProps,
    | 'title'
    | 'subtitle'
    | 'termsLinkProps'
    | 'codeTextInputProps'
    | 'submitButtonProps'
    | 'resendButtonProps'
  >
> = {
  title: 'WELCOME',
  subtitle: 'Enter your one-time access code we sent to your email.',
  termsLinkProps: {
    content: 'I agree to the <a href="#" target="_blank">Terms and Policy</a>',
  },
  submitButtonProps: {
    borderRadius: '100px',
    htmlType: 'submit',
    children: 'SUBMIT',
  },
  codeTextInputProps: {
    name: 'accessCode',
    id: 'access-code',
    placeholder: 'Enter Passcode',
    inputType: 'text',
    validationOptions: {
      required: 'code cannot be empty',
      minLength: {
        value: 1,
        message: `your code should be at least a character`,
      },
    },
  },
  resendButtonProps: {
    buttonColor: 'transparent',
    children: 'Resend My Code',
    fontSize: '10px',
  },
};

export interface IAccessCodeProps extends ModuleProperties {
  title: string;
  subtitle?: string;
  accessCodeLength?: number;
  showResendLink?: boolean;
  submitButtonProps: ButtonProps;
  codeTextInputProps: TextInputProps;
  termsLinkProps?: {content: string};
  resendButtonProps?: ButtonProps;
  routeOnCodeAuthSuccess?: string;
}

export type AccessCodeComponentDefinition = ContentModule<
  'AccessCode',
  IAccessCodeProps & SchemaType
>;

export const AccessCodeComponent: VFC<AccessCodeComponentDefinition> = (
  definition
) => {
  const moduleIdentifiers = extractModuleIdentifiers(definition);

  const {id, mid, config} = definition;
  const {
    magicLinkSupport,
    title,
    subtitle,
    codeTextInputProps,
    termsLinkProps,
    resendButtonProps,
    submitButtonProps,
    accessCodeLength,
    showResendLink = false,
    showTermsCheckbox = true,
    accessCodeErrorMessage = 'Incorrect access code provided',
  } = definition.props;

  const styles = css`
    ${definition.style}
    ${definition.props.styleAttr}
  `;

  const showId = useShowId();
  const {observable, broadcast} = useShowInstructions(
    AccessCodeInstructionSchema,
    definition
  );
  const [state, dispatch] = useMachine(AccessCodeMachine, {
    context: {broadcast, showId},
  });
  const {register, formState, handleSubmit, setError, setValue, getValues} =
    useForm({
      /**
       * @param mode defines when validation is triggered.
       * AccessCode is set at 'onSubmit'
       */
      mode: 'onSubmit',
    });
  const errors = formState.errors;

  useEffect(() => {
    if (state.matches('failure')) {
      setError('accessCode', {message: accessCodeErrorMessage});
    }
  }, [accessCodeErrorMessage, setError, state]);
  useSubscription(observable, {
    next: (instruction) => dispatch(instruction),
  });

  useEffect(() => {
    const onAccessCodeSuccess = (e: AccessCodeSuccessEvent): void => {
      const {detail} = e;
      broadcast({
        type: 'AccessCode:on-success',
        meta: {
          showId: detail.showId,
          attendeeId: detail.attendee.id,
          attendeeEmail: detail.attendee.email,
          attendeeName: detail.attendee.name,
        },
      });
    };
    document.body.addEventListener('AccessCode:success', onAccessCodeSuccess);
    return () => {
      document.body.removeEventListener(
        'AccessCode:success',
        onAccessCodeSuccess
      );
    };
  }, [broadcast]);

  const onSubmit: SubmitHandler<FieldValues> = useCallback(
    (data) => {
      // Send the instruction to the state machine, which will trigger the
      // broadcast
      if (typeof data.accessCode !== 'string') {
        return;
      }
      dispatch({
        type: 'AccessCode:verify',
        meta: {accessCode: data.accessCode, showId},
      });
    },
    [dispatch, showId]
  );

  const validationOptions = {
    required: 'code cannot be empty',
    minLength: {
      value: accessCodeLength || 10,
      message: `your code should be at least ${
        accessCodeLength || 10
      } characters`,
    },
    maxLength: {
      value: accessCodeLength || 10,
      message: `your code should be no more than ${
        accessCodeLength || 10
      } characters`,
    },
  };

  const termsLinkContent = useParsedText(
    termsLinkProps?.content ?? 'No content for terms link provided'
  );

  const magicLinkCodeKey = 'ac';
  const hasAutoSubmitExecuted = useRef(false);

  useEffect(() => {
    if (
      !window?.location ||
      config.scope !== 'attendee' ||
      magicLinkSupport === 'none' ||
      hasAutoSubmitExecuted.current === true
    ) {
      return;
    }

    // ensure effect only runs once
    hasAutoSubmitExecuted.current = true;

    const query = window.location.search;
    const params = new URLSearchParams(query);
    const ac = params.get(magicLinkCodeKey) ?? '';

    if (
      magicLinkSupport === 'auto-fill' ||
      magicLinkSupport === 'auto-submit'
    ) {
      const inputKey = defaultAccessCodeProps.codeTextInputProps.name;
      if (getValues(inputKey) === '') {
        setValue(inputKey, ac);
      }
      if (
        ac.length > 0 &&
        !showTermsCheckbox &&
        magicLinkSupport === 'auto-submit'
      ) {
        handleSubmit(onSubmit)();
      }
    }
  }, [
    config.scope,
    getValues,
    handleSubmit,
    magicLinkSupport,
    onSubmit,
    setValue,
    showTermsCheckbox,
  ]);
  return (
    <Styled.Container id={id} css={styles} className="access-code-wrapper">
      <Styled.Title className="access-code-title">
        {title || defaultAccessCodeProps.title}
      </Styled.Title>
      <Styled.Subtitle className="access-code-subtitle">
        {subtitle || defaultAccessCodeProps.subtitle}
      </Styled.Subtitle>
      <Styled.Form autoComplete="off" onSubmit={handleSubmit(onSubmit)}>
        {termsLinkProps && showTermsCheckbox && (
          <Styled.CheckboxInputGroup className="access-code-checkbox-input-group">
            <Styled.Checkbox
              role="checkbox"
              className="access-code-checkbox-input"
              type="checkbox"
              {...register('terms', {
                required: 'please agree to the terms to continue',
              })}
            />
            <Styled.CheckboxLabel className="access-code-checkbox-label">
              {termsLinkContent}
            </Styled.CheckboxLabel>
          </Styled.CheckboxInputGroup>
        )}
        <Styled.CodeInputGroup className="access-code-text-input-group">
          <TextInputComponent
            config={config}
            {...moduleIdentifiers}
            mid={`${mid}-access-code-text-input`}
            component={'TextInput'}
            props={{
              disabled: state.matches('pending'),
              parentRegister: register,
              ...codeTextInputProps,
              name: defaultAccessCodeProps.codeTextInputProps.name,
              inputType: defaultAccessCodeProps.codeTextInputProps.inputType,
              validationOptions: accessCodeLength
                ? validationOptions
                : defaultAccessCodeProps.codeTextInputProps.validationOptions,
              styleAttr: `${codeTextInputProps?.styleAttr}
              ${Styled.codeTextInputStyle}`,
            }}
          />
          {!('href' in defaultAccessCodeProps.submitButtonProps) && (
            <ButtonComponent
              config={config}
              {...moduleIdentifiers}
              mid={`${mid}-access-code-submit-button`}
              component={'Button'}
              props={{
                ...submitButtonProps,
                disabled: state.matches('pending'),
                isLoading: state.matches('pending'),
                children:
                  submitButtonProps?.children ||
                  defaultAccessCodeProps?.submitButtonProps?.children,
                borderRadius:
                  defaultAccessCodeProps.submitButtonProps?.borderRadius,
                htmlType: defaultAccessCodeProps.submitButtonProps.htmlType,
              }}
            />
          )}
        </Styled.CodeInputGroup>

        {(errors?.['terms'] || errors?.['accessCode']) && (
          <Styled.ErrorMessage
            data-testid="access-code-text-input-error-message"
            className="access-code-text-input-error-message"
          >
            {errors?.['terms']?.message ?? errors?.['accessCode']?.message}
          </Styled.ErrorMessage>
        )}
      </Styled.Form>

      {resendButtonProps && showResendLink && (
        <div className="resend-button-component">
          <ButtonComponent
            config={config}
            {...moduleIdentifiers}
            mid={`${mid}-access-code-resend-button`}
            component={'Button'}
            props={{
              onClick: () => {
                //TODO: implement logic to handle request to resend access code
                console.log('TODO: resend code');
              },
              ...resendButtonProps,
              buttonColor:
                defaultAccessCodeProps.resendButtonProps?.buttonColor,
              children:
                resendButtonProps.children ||
                defaultAccessCodeProps.resendButtonProps?.children,
              fontSize:
                resendButtonProps.fontSize ||
                defaultAccessCodeProps.resendButtonProps?.fontSize,
              styleAttr: `padding: 0;`,
            }}
          />
        </div>
      )}
    </Styled.Container>
  );
};
