import {
  OptionalString,
  ComponentAdmin,
  StringEnum,
  PublishesTo,
  SubscribesTo,
  styleAttr,
  styleAttrUi,
} from '@backstage-components/base';
import {Static, Type} from '@sinclair/typebox';

export const reactName = 'Modal';
export const name = reactName;
export const description = reactName;

export const defaultFieldData = {
  size: 'md',
  motionPreset: 'slideInBottom',
  modalBodyProps: {
    padding: '20px',
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
  },
};

const sizeAttr = StringEnum(
  [
    'xs',
    'sm',
    'md',
    'lg',
    'xl',
    '2xl',
    '3xl',
    '4xl',
    '5xl',
    '6xl',
    'full',
  ] as const,
  {
    title: 'Size',
    description:
      'For more information about modal size presets see https://chakra-ui.com/docs/overlay/modal#modal-sizes',
    default: 'xl',
  }
);

const buttonSizeAttr = StringEnum(['sm', 'md', 'lg'] as const, {
  title: 'Size',
  description:
    'For more information about button size presets see https://chakra-ui.com/docs/components/close-button#button-size',
  default: 'md',
});

const motionPresetAttr = StringEnum(
  ['slideInBottom', 'slideInRight', 'scale', 'none'] as const,
  {
    title: 'Motion Preset',
    description:
      'For more information about motion presets see https://chakra-ui.com/docs/overlay/modal#changing-the-transition',
    default: 'slideInBottom',
  }
);

export const schema = Type.Object({
  // Chakra Modal Props
  size: sizeAttr,
  motionPreset: motionPresetAttr,
  blockScrollOnMount: Type.Optional(
    Type.Boolean({
      title: 'Lock Body Scroll',
      description: 'The body scroll will be locked when modal is open.',
    })
  ),
  isCentered: Type.Optional(
    Type.Boolean({
      title: 'Is Centered',
      description: 'The modal will be centered vertically on screen.',
    })
  ),
  closeOnOverlayClick: Type.Optional(
    Type.Boolean({
      title: 'Close On Overlay Click',
      description: 'The modal will close when the overlay is clicked.',
    })
  ),
  returnFocusOnClose: Type.Optional(
    Type.Boolean({
      title: 'Return Focus on Close',
      description:
        'The modal will return focus to the element that triggered it when it closes.',
    })
  ),
  preserveScrollBarGap: Type.Optional(
    Type.Boolean({
      title: 'Preserve Scrollbar Gap',
      description:
        "A `padding-right` will be applied to the body element that's equal to the width of the scrollbar.",
    })
  ),
  // Chakra Modal Content Props
  modalContentProps: Type.Optional(
    Type.Object(
      {
        backgroundColor: OptionalString({title: 'Background Color'}),
        borderRadius: OptionalString({title: 'Border Radius'}),
        top: OptionalString({
          title: 'Top',
          description:
            'Position from top of viewport if `isCentered` is not checked.',
        }),
        styleAttr: {
          ...styleAttr,
          title: 'Custom Styles',
          description: 'Custom styles for the modal content',
        },
      },
      {title: 'Modal Content'}
    )
  ),
  // Chakra Modal Overlay Props
  modalOverlayProps: Type.Optional(
    Type.Object(
      {
        backgroundColor: OptionalString({title: 'Background Color'}),
        styleAttr: {
          ...styleAttr,
          title: 'Custom Styles',
          description: 'Custom styles for the modal overlay',
        },
      },
      {title: 'Modal Overlay'}
    )
  ),
  // Chakra Modal Body Props
  modalBodyProps: Type.Optional(
    Type.Object(
      {
        backgroundColor: OptionalString({title: 'Background Color'}),
        padding: OptionalString({
          title: 'Padding',
          default: defaultFieldData.modalBodyProps.padding,
        }),
        margin: OptionalString({title: 'Margin'}),
        minH: OptionalString({title: 'Min Height'}),
        minW: OptionalString({title: 'Min Width'}),
        h: OptionalString({title: 'Height'}),
        w: OptionalString({title: 'Width'}),
        backgroundImage: OptionalString({title: 'Background Image'}),
        backgroundRepeat: OptionalString({
          title: 'Background Repeat',
          default: defaultFieldData.modalBodyProps.backgroundRepeat,
        }),
        backgroundAttachment: OptionalString('Background Attachment'),
        backgroundPosition: OptionalString({
          title: 'Background Position',
          default: defaultFieldData.modalBodyProps.backgroundPosition,
        }),
        backgroundSize: OptionalString({
          title: 'Background Size',
          default: defaultFieldData.modalBodyProps.backgroundSize,
        }),
        styleAttr: {
          ...styleAttr,
          title: 'Custom Styles',
          description: 'Custom styles for the modal body component',
        },
      },
      {title: 'Modal Body'}
    )
  ),
  modalCloseButtonProps: Type.Optional(
    Type.Object(
      {
        size: buttonSizeAttr,
        hideCloseButton: Type.Optional(
          Type.Boolean({title: 'Hide Close Button', default: false})
        ),
        color: OptionalString({
          title: 'Close Icon Color',
        }),
        borderRadius: OptionalString({
          title: 'Button Border Radius',
        }),
        backgroundColor: OptionalString({title: 'Background Color'}),
        border: OptionalString({title: 'Border'}),
        padding: OptionalString({title: 'Padding'}),
        margin: OptionalString({title: 'Margin'}),
        styleAttr: {
          ...styleAttr,
          title: 'Custom Styles',
          description: 'Custom styles for the modal close button',
        },
      },
      {title: 'Close Button'}
    )
  ),
});

export const instructions = Type.Union([
  SubscribesTo({
    topic: `${reactName}:open`,
    description: 'Open a modal',
  }),
  SubscribesTo({
    topic: `${reactName}:close`,
    description: 'Close a modal',
  }),
  PublishesTo({
    topic: `${reactName}:on-open`,
    description: 'A modal has opened',
  }),
  PublishesTo({
    topic: `${reactName}:on-close`,
    description: 'A modal has closed',
  }),
]);

export type SchemaType = Static<typeof schema>;

export const uiSchema = {
  modalOverlayProps: {...styleAttrUi},
  modalContentProps: {...styleAttrUi},
  modalBodyProps: {...styleAttrUi},
  modalCloseButtonProps: {...styleAttrUi},
};

export const ComponentDefinition: ComponentAdmin = {
  id: '2b43f6bf-587f-41f2-820c-f526ba3a1e35',
  reactName,
  name,
  slug: reactName,
  description,
  version: 1,
  defaultFieldData,
  slotConfiguration: {
    items: {maxModules: 100, displayWidth: 12, title: 'Modules'},
  },
  schema,
  uiSchema,
  instructions,
};
