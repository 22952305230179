import {gql} from '@apollo/client';

export const GetAttendeeQuery = gql`
  query QueryAccessCodeSession {
    self {
      id
      attendeeTags
      attendee {
        id
        name
        email
        chatTokens {
          token
        }
      }
    }
  }
`;

export const VerifyAccessCodeMutation = gql`
  mutation VerifyAccessCode($showId: UUID!, $accessCode: String!) {
    verifyAccessCode(data: {showId: $showId, accessCode: $accessCode}) {
      id
      showId
      sessionToken
      attendeeTags
      attendee {
        id
        name
        email
        chatTokens {
          token
        }
      }
    }
  }
`;
